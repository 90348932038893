import React from 'react';
import Man1 from 'images/men/bulle1.svg';
import Man2 from 'images/men/bulle2.svg';
import Man3 from 'images/men/bulle3.svg';

export const testimonies = [
  {
    testimony:
      'J’ai toujours été intriguée par la sexualité, ça avait l’air absolument génial. Mais quelle déception quand j’ai enfin pu faire ma première fois, même 3 ans plus tard, je ne parvenais pas à prendre du plaisir avec mon copain. J’ai voulu en savoir plus en passant via des pros et je me suis rendue compte que je ne me connaissais tout simplement pas. Merci encore.',
    patient: 'Lola',
    city: '',
    age: '19 ans',
    Icon: Man1,
    backgroundColorClass: 'lc-light-blue',
    flower: true,
    target: false,
  },
  {
    testimony:
      'En parlant avec mes amies et en me renseignant sur le sujet, je me rendais compte que le plaisir que je pouvais ressentir lorsque je faisais l’amour était bien loin de ce que je pouvais atteindre. Je connais mon corps, mais je n’avais pas identifié certains blocages qui m’empêchaient d’atteindre le plaisir, le vrai. C’est du passé maintenant !',
    patient: 'Ludmila',
    city: '',
    age: '28 ans',
    Icon: Man3,
    backgroundColorClass: 'lc-light-yellow',
    flower: false,
    target: true,
  },
  {
    testimony:
      'Suite à un traumatisme, impossible de renouer avec mes orgasmes. Je n’étais même plus sûre de ce que j’avais pu ressentir auparavant. J’en ai parlé avec une sexologue pour en avoir le cœur net et en plus de me rassurer, nous avons mis en place plusieurs techniques pour renouer avec ma sexualité, et même en inventer une nouvelle. OH YES.',
    patient: 'Eléonore',
    city: '',
    age: '29 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: true,
    target: false,
  },
  {
    testimony:
      'Jusqu’ici je n’avais atteint l’orgasme qu’en solo. Je me suis rendue compte que je m’étais imposée une vraie barrière lorsque j’avais des rapports avec ma copine, comme si je n’osais pas me lâcher. On a travaillé dur avec la sexologue qui m’a suivi et aujourd’hui, je me sens complètement libérée. Et même dans la vie de tous les jours, ça fait une différence. Merci.',
    patient: 'Laurie',
    city: '',
    age: '25 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: false,
    target: true,
  },
];

export const sections = [
  {
    title:
      'Quelle est la définition d’un trouble de l’orgasme chez la femme ?',
    body: (
      <div>
        <p>
          Depuis une vingtaine d’années, les définitions des troubles sexuels féminins ont beaucoup
          évolué. Elles tiennent en effet mieux compte de l’âge, du statut hormonal, de la relation
          avec le ou la partenaire, mais aussi du vécu subjectif qui accompagne le trouble sexuel.
        </p>
        <p>
          Ainsi, les troubles de l’orgasme se distinguent donc
          <b> des troubles du désir </b>
          (baisse ou absence de libido),
          <b> des troubles de l’excitation </b>
          ou
          <b> des problèmes liés aux douleurs.</b>
        </p>
        <p>
          Pour commencer, comment définir l’orgasme ?
        </p>
        <p>
          L’orgasme est aujourd’hui défini comme «
          <i>
            {' '}
            une sensation de plaisir intense provoquant
            un état de conscience modifié, accompagné de contractions de la musculature des muscles
            striés à l’entrée du vagin qui amènent une sensation de bien-être
            {' '}
          </i>
          ».
        </p>
        <p>
          À l’inverse, les médecins considèrent un trouble de l’orgasme selon la définition
          suivante : «
          <i>
            {' '}
            malgré une excitation sexuelle importante, on note soit une absence
            d’orgasme, soit une intensité des sensations orgasmiques nettement diminuée, soit
            un orgasme nettement retardé, et cela, quel que soit le type de stimulation
            {' '}
          </i>
          ».
        </p>
        <p>
          Cela laisse évidemment la place à la subjectivité de chaque femme, mais pour que trouble
          de l’orgasme soit avéré, il doit aussi être à l’origine et responsable d’une souffrance
          émotionnelle subjective et/ou des difficultés personnelles/interpersonnelles.
        </p>
      </div>
    ),
  },
  {
    title:
      'Quelles sont les causes de l’anorgasmie ?',
    body: (
      <div>
        <p>
          L’anorgasmie désigne l’absence systématique et l’impossibilité à atteindre l’orgasme
          lors d' un rapport sexuel. Mais comme nous le disions précédemment, la définition d’un
          trouble de l’orgasme est plus large, et désigne aussi les difficultés à obtenir des
          orgasmes (dysorgasmie), un manque de sensations ou l’absence de plaisir.
        </p>
        <p>
          Un trouble de l’orgasme désigne tout autant l’impossibilité à atteindre l’orgasme
          (anorgasmie), que des sensations nettement diminuées ou un orgasme anormalement retardé,
          malgré tous les types de stimulations.
        </p>
        <p>
          Beaucoup de facteurs doivent être pris en considération et susceptibles d’induire un
          trouble de l’orgasme, parmi :
          <br />
          <p className="ml-4 mt-3">
            • Des troubles de l’humeur ;
            <br />
            • La peur de l’attachement ;
            <br />
            • Un sentiment de culpabilité ;
            <br />
            • Une éducation sexuelle insuffisante ;
            <br />
            • Le fait de trop d’anticiper le rapport sexuel ;
            <br />
            • Une mauvaise estime de soi ;
            <br />
            • Des abus sexuels ;
            <br />
            • Et tous les facteurs organiques : maladies chroniques, intervention chirurgicale,
            suites d’un cancer, etc.
          </p>
        </p>
        <p>
          Enfin, il semble que l’orgasme nécessite aussi une certaine forme d’apprentissage chez de
          nombreuses femmes, tout comme la part de l’expérience émotionnelle (ainsi que le rôle du
          partenaire) est importante dans le mécanisme de l’orgasme.
        </p>
        <p>
          Par ailleurs, les études montrent que la satisfaction sexuelle ne provient pas
          nécessairement de l’orgasme, mais plutôt de la qualité de la relation et de la
          communication.
        </p>
      </div>
    ),
  },
  {
    title:
      'Traitements des troubles de l’orgasme ou du manque de plaisir',
    body: (
      <div>
        <p>
          Comme pour toutes les difficultés sexuelles, une information éclairante et un discours
          rassurant suffisent souvent à améliorer la situation. Un trouble de l’orgasme primaire est
          en effet  souvent dû à de l’inexpérience ou à des maladresses.
        </p>
        <p>
          La thérapie peut donc être orientée sur un apprentissage de la sexualité afin de corriger
          certaines fausses croyances ou des idées reçues. La prise en charge psycho-sexuelle est
          également essentielle pour trouver « le chemin » qui mène vers l’orgasme.
        </p>
        <p>
          Les thérapies psycho-sexuelles permettent de corriger certains comportements inadaptés,
          comme un découragement et une impatience liés aux difficultés sexuelles, par une meilleure
          information de la sexualité et l’exploration du corps.
        </p>
        <p>
          Elles permettront aussi de gérer aussi les symptômes annexes au trouble de l’orgasme,
          comme les perturbations émotionnelles, de travailler sur les perceptions sensorielles,
          et d’offrir un soutien psychologique à la patiente.
        </p>
        <p>
          Le couple peut également suivre une thérapie.
        </p>
      </div>
    ),
  },
  {
    title:
      'Comment téléconsulter une sexologue sur Mia ?',
    body: (
      <div>
        <p>
          Avant la téléconsultation, il est d’abord nécessaire de remplir un questionnaire
          médical précis, qui permettra au sexologue de connaître d’éventuels antécédents
          médicaux. Ensuite, la patiente peut choisir le créneau horaire qui lui convient
          le mieux, ainsi que le jour et la date.
        </p>
        <p>
          3 modes de téléconsultation s’offrent alors :
          <p className="ml-4 mt-3">
            • Par
            <b> vidéo ;</b>
            <br />
            <b>• Messages sécurisés ;</b>
            <br />
            • Ou par
            <b> téléphone.</b>
          </p>
        </p>
        <p>
          Une fois le RDV confirmé et les informations personnelles remplies, le prix de la
          téléconsultation doit être préréglé sur la plateforme.
        </p>
        <p>
          Lorsque la consultation est terminée, le dossier patient est accessible à tout moment
          dans l’espace personnel, ainsi que le compte-rendu de la consultation et les
          recommandations d’accompagnement.
        </p>
      </div>
    ),
  },
  {
    title:
      'Quels sont les avantages de Mia.co ?',
    body: (
      <div className="ml-4">
        <p>
          • Une équipe de praticiennes (sexologues, sages-femmes, thérapeutes de couple)
          spécialistes de la sexualité féminine et spécialement formées à la pratique de la
          téléconsultation ;
        </p>
        <p>
          • Un comité scientifique composé de médecins sexologues, de professeures d’université
          en sexologie et de membres dirigeants d’associations de sexologues ;
        </p>
        <p>
          • Une consultation de 30 min à 45 euros (les prix en cabinet varient de 80 à 100 euros) ;
        </p>
        <p>
          • La livraison de produits d’accompagnement en 24 ou 48H.
        </p>
      </div>
    ),
  },
];
