import React from 'react';
import VideoSection from 'components/common/VideoSection';

const video = {
  filename: 'videos/thumbnails/consulter-orgasme-mia-co.png',
  alt: '',
  video: 'https://www.youtube.com/embed/nHMvaLQtrLo',
  intro:
    '',
};

const VideoPlaisir = () => <VideoSection {...video} />;

export default VideoPlaisir;
