import React, { useState } from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';

import Container from 'react-bootstrap/Container';
import { Layout } from 'components/common';
import TextSectionList from 'components/common/TextSectionList';
import Section from 'components/common/Section';
import TestimoniesSection from 'components/common/TestimoniesSection';
import Image from 'components/common/utils/Image';
import { Button } from 'react-bootstrap';

import StyledLink from 'components/common/utils/StyledLink';

import VideoPlaisir from '../common/VideoPlaisir';

import { sections, testimonies } from './data';

import './styles.sass';

import HowItWorksSection from '../HowItWorksSection';
import Collapse from 'react-bootstrap/Collapse';
import Hush from 'images/icons/hush.svg';
import Medal from 'images/icons/medal.svg';
import PharmaCross from 'images/icons/pharma-cross-with-heart.svg';
import TherapistCarousel from '../common/TherapistsCarousel';

const IndexPage = () => {
  const [open, setOpen] = useState(false);
  return (
    <Layout
      footerCtaPath="/consulter-baisse-de-libido/"
      showLinksInFooter={false}
      backgroundBeforeFooter="background-color-pale-yellow"
    >
      <CharlesMeta
        data={{
          title: "Absence de plaisir ou d'orgasme ? Consultez en ligne une sexologue",
          description:
            "Difficultés à prendre du plaisir ou à atteindre l'orgasme ? Consultez une sexologue en ligne sur Mia.co par vidéoconférence, téléphone ou messagerie 7 jours sur 7.",
        }}
      />
      <div className="text-left landing-page">
        <Section className="text-white d-flex flex-column background-color-pale-yellow home-top-section patho-top-section plaisir-top-section m-lg-0">
          <h1 className="h1 text-center main-title-patho align-self-center d-lg-none mb-1">
            En quête de plaisir&nbsp;? Et si vous en parliez à une sexologue&nbsp;?
          </h1>
          <div className="desktop-home-top-div desktop-patho-top-div d-none d-lg-flex">
            <div className="home-top-left">
              <div>
                <h1 className="h1-patho-top-left">
                  En quête de plaisir&nbsp;?
                  <br />
                  Et si vous en parliez à une sexologue&nbsp;?
                </h1>
                <div className="d-flex cta-div">
                  <StyledLink
                    variant="orange"
                    path="/consulter-plaisir/"
                    className=""
                    margin
                  >
                    CONSULTER
                  </StyledLink>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column desktop-videoPL justify-content-lg-center align-items-lg-end">
              <VideoPlaisir className="" />
              <span className="video-patho-text">
                Je suis Camille Bataillon, la sexologue qui a créé ce protocole de
                téléconsultation.
              </span>
            </div>
          </div>
          <div className="info-div info-div-patho">
            <div className="d-flex justify-content-center d-lg-none">
              <div className="d-flex cta-div">
                <Button
                  path="/consulter-plaisir/"
                  variant="orange"
                  size="lg"
                  className="style-most-cta"
                >
                  CONSULTER
                </Button>
              </div>
            </div>
            <div className="info-list info-list-patho">
              <div className="text-left">
                <div className="img-wrapper"><Hush /></div>
                <span className="hush-span">CONFIDENTIEL & SÉCURISÉ</span>
              </div>
              <div>
                <div className="img-wrapper"><Medal /></div>
                <span className="medal-span">SEXOLOGUES DIPLÔMÉES</span>
              </div>
              <div>
                <div className="img-wrapper"><PharmaCross /></div>
                <span className="pharmacross-span">SUIVIS & ASSISTANCE ILLIMITÉS</span>
              </div>
            </div>
          </div>
          <Container className="d-flex flex-column align-items-center video-patho-container d-lg-none">
            <VideoPlaisir />
            <span className="video-patho-text">
              Je suis Camille Bataillon, la sexologue qui a créé ce protocole de téléconsultation.
            </span>
          </Container>
        </Section>
        <HowItWorksSection />
        <Section className="d-flex flex-column flex-lg-row patho-explanation-section justify-content-lg-center align-items-lg-center">
          <div className="d-none d-lg-flex patho-explanation-left">
            <Image filename="women-pleasure.png" className="patho-img explainations-img-purple" />
          </div>
          <div className="d-flex flex-column patho-explanation-right">
            <h2 className="section-title h2-title patho-title">
              78% des femmes ont déjà eu des difficultés à atteindre l’orgasme
            </h2>
            <Container className="patho-container">
              <div className="patho-paragraph">
                <p>
                  De nombreuses femmes éprouvent des difficultés à prendre du plaisir ou atteindre
                  l'orgasme : on parle même parfois d'anorgasmie ou d’absence d’orgasme. Et
                  malheureusement, de trop nombreux tabous ou préjugés perdurent encore autour
                  de ce sujet.
                </p>
                <p>
                  Pratiques sexuelles « phallo-centrées », manque de communication entre les
                  partenaires, vision erronée de la sexualité ou méconnaissance du corps féminin,
                  l’accès au plaisir ne va pas de soi pour toutes les femmes.
                </p>
                <p>
                  Malgré une certaine libération du discours autour de la sexualité – et notamment
                  de la masturbation féminine – l’absence de plaisir (ou des difficultés à
                  atteindre l’orgasme) s’accompagne souvent de difficultés personnelles et
                  émotionnelles : les sexologues de Mia.co sont donc spécialement formées pour
                  répondre à toutes ces questions.
                </p>
                <p className="d-none d-lg-block">
                  Après avoir écarté certains facteurs médicaux assez rares, la sexologue pourra
                  identifier clairement l’origine du problème avec vous avant de pouvoir le
                  régler.
                </p>
                <p className="d-none d-lg-block">
                  De nombreux facteurs psychologiques peuvent par exemple interférer avec la
                  sexualité&nbsp;; tout comme l’éducation sexuelle est fondamentale pour vivre une
                  sexualité épanouie. L’important est de pouvoir en parler librement et de
                  briser les tabous. C’est maintenant possible sur Mia.co.
                </p>
                <Collapse in={open} className=" d-lg-none">
                  <div id="example-collapse-text">
                    <p>
                      Après avoir écarté certains facteurs médicaux assez rares, la sexologue pourra
                      identifier clairement l’origine du problème avec vous avant de pouvoir le
                      régler.
                    </p>
                    <p>
                      De nombreux facteurs psychologiques peuvent par exemple interférer avec la
                      sexualité&nbsp;; tout comme l’éducation sexuelle est fondamentale pour vivre
                      une sexualité épanouie. L’important est de pouvoir en parler librement et de
                      briser les tabous. C’est maintenant possible sur Mia.co.
                    </p>
                  </div>
                </Collapse>
                <div className="d-flex flex-column d-lg-none">
                  {open === false ? (
                    <span
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                      className="read-more align-self-end"
                    >
                      Lire la suite
                    </span>
                  ) : (
                    <span
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                      className="read-more align-self-end"
                    >
                      Voir moins
                    </span>
                  )}
                </div>
              </div>
            </Container>
            <div className="cta-div align-self-center align-self-lg-start">
              <StyledLink
                variant="orange"
                path="/consulter-plaisir/"
                className=""
                margin
              >
                CONSULTER
              </StyledLink>
            </div>
          </div>
        </Section>
        <Section className="d-flex flex-column therapist-carousel-section">
          <div className="d-flex flex-column therapist-div-wrapper">
            <h2 className="h2-title text-white text-center">DES EXPERTES POUR VOUS ACCOMPAGNER</h2>
            <TherapistCarousel />
          </div>
        </Section>
        <TestimoniesSection testimonies={testimonies} path="consulter-plaisir" />
        <Section className="d-flex flex-column align-items-center faq-section">
          <Container className="d-flex flex-column align-items-center">
            <h3 className="section-title text-center">
              FAQ
            </h3>
            <TextSectionList sections={sections} />
          </Container>
        </Section>
      </div>
    </Layout>
  );
};

export default IndexPage;
